<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="!menuId">
          Add Menu
        </h1>
        <h1 v-if="menuId">
          Edit menu
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="primary"
          @click="backtolist()"
        >
        Back On Navigation Menu
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!menuId"
          title="Navigation Menu Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="menuId"
          title="Navigation Menu Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
      <div v-if="showAlertSlugexit">
        <el-alert
          title="The slug has already been taken."
          type="error"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition" 
  >
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Menu Name"
          prop="name"
        >
          <el-input
            v-model="data.name"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      > 
        <el-form-item
          label="Page Link"
          prop="page_route"
        >
          <el-select
            v-model="data.link_name"
            name="page_route"
          >
            <el-option
              v-for="item in pagelinks" 
              :key="item.label"
              :value="item.value"
              :label="item.label"
			  @click="onClick(false)"
            >
              {{ item.label }}
            </el-option>
            <el-option value='No Link' @click="onClick(true)">
            No Link</el-option>
          </el-select>
		 <el-input v-if="setslugistrue"
            v-model="data.otherslug"
            type="text"
			style="margin-top: 15px;"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Parent"
          prop="parent_id"
        >
        <el-select 
          v-model="data.parent_name"  
          name="parent_id">
                  <el-option value='No Link'>
            No Link</el-option>
                  <el-option 
                  v-for="item in parentslinks" 
                  :key="item.label"
                  :value="item.value"
                  :label="item.label"           
                  >
                  {{ item.label }}
                  </el-option>
                </el-select>
        </el-form-item>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Show On Top"
          prop="top"
        >
        <el-select
            v-model="data.top"
            name="top"
          >
            <el-option
              v-for="item in selectshowtop" 
              :key="item.label"
              :value="item.value"
              :label="item.label"
            >
              {{ item.label }}
            </el-option>
           </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >       
        <el-form-item
          label="Sort Order"
          prop="sort_order"
        >
        <el-input
            v-model="data.sort_order"
            type="text"
          />
        </el-form-item>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Status"
          prop="status"
        >
          <el-select
            v-model="data.status"
            name="status"
          >
            <el-option
              v-for="item in selectstatus" 
              :key="item.label"
              :value="item.value"
              :label="item.label"
            >
              {{ item.label }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>  
     <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >  
      <el-form-item
          label="Page Type"
          prop="pagetype"
        >
          <el-select
            v-model="data.pagetype"
            name="pagetype"
          >
            <el-option
              v-for="item in pageTypes" 
              :key="item.label"
              :value="item.value"
              :label="item.label"
            >
              {{ item.label }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
     </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item>
          <input
            id="menuId"
            v-model="menuId"
            name="menuId"
            type="hidden"
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            Submit
          </el-button>
          <el-button v-if="!menuId" @click="resetForm('ruleForm')">
            Reset
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>   
  </el-form>
</template>
<script>

import MenuService from "@/api/menu.service";

  export default {
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Menu Name.'));
        } else {
          callback();
        }
      };
      
      var checkSlug = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please choose Menu Link.'));
        } else {
          callback();
        }
      };

      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        setslugistrue: false,
        labelPosition:'left',
        menuId: this.$route.params.menuId,
        pagelinks:[],
        parentslinks:[],
		selectstatus:[{
          value: 'Enabled',
          label: 'Enabled'
        }, {
          value: 'Disabled',
          label: 'Disabled'
        }],
    selectshowtop:[{
        value: 'Yes',
        label: 'Yes'
      }, {
        value: 'No',
        label: 'No'
      }],
       pageTypes:[{
        value: 'Buyer',
        label: 'Buyer'
      }, {
        value: 'Seller',
        label: 'Seller'
      }, {
        value: 'Other',
        label: 'Other'
      }, {
        value: 'Web',
        label: 'Web'
      }, {
        value: 'Information',
        label: 'Information'
      }, {
        value: 'Service',
        label: 'Service'
      }, {
        value: 'Developments',
        label: 'Developments'
      }],
        data: {
          name: null,
          parent_name:null,
          link_name:null,
          page_route: null,
          parent_id: null,
          top:null,
          sort_order:null,
          otherslug:null,
          status: null,
        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          name: [
            { validator: checkName, trigger: 'blur' }
          ],
          page_route: [
            { validator: checkSlug, trigger: 'blur' }
          ]
        }
      };
    },
  watch: {
    data: function () {
      if(!this.data.parent_name && this.data.parent_id) {
      this.data.parent_name = this.extractNameById(this.data.parent_id, this.parentslinks);
       }
       if(!this.data.link_name && this.data.page_route) {
      this.data.link_name = this.extractNameById(this.data.page_route, this.pagelinks);
       }
    },
    parentslinks: function () {
      this.data.parent_name = this.extractNameById(this.data.parent_id, this.parentslinks);
    },
    pagelinks: function () {
      this.data.link_name = this.extractNameById(this.data.page_route, this.pagelinks);
    }
  },
	mounted() {  
    this.getmenupages();
    this.getparentpages();
    if(this.menuId){
      this.getMenu();
    }
  },
  computed: {
      loadEditor: function () {
        return !this.menuId || (this.menuId && this.data.description);
        },
  },
    methods: {
      async getmenupages(){
      return MenuService.getmenupages().then(response => {
      this.pagelinks=response.data.data;
      });
      },
      async getparentpages(){
      return MenuService.getparentpages().then(response => {
      this.parentslinks=response.data.data;
      });
      },
      extractNameById(needle, hayStack) {
      let val = null;
      val = hayStack.filter(function(element){
      if(element['value'] == Number(needle)) {
      return element['label'];
      }
      });
      if(val.length) {
      return val[0]['label'];
      }
      return null;
      },
      submitForm(formName) {
        this.data.parent_id = this.data.parent_name;
        this.data.page_route = this.data.link_name;
        this.$refs[formName].validate((valid) => {
          if (valid && !this.menuId) {
             return MenuService.add(this.data).then(response => {
                // this.data = response.data
                this.showAlertAdded=true 
                return response
              });
          } else if (valid && this.menuId) {
            return MenuService.update(this.menuId, this.data).then(response => {
              this.showAlertAdded=true;
              return response;

            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	backtolist(){
		this.$router.push("/menu");
	},
	getMenu(){
    return MenuService.get(this.menuId).then(response => {
        this.data = response.data.data;
        return response;
      });
	},
	onClick(setslug) {
		if(setslug===true){
			this.setslugistrue=true;
		}else{
			this.setslugistrue=false;
		}
	 },
 }
}
</script>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 0px;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
    div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
.el-select{
  width:100%;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>
